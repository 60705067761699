import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Apply, List } from '@components';
import content, { positions } from '@content/writers';
import { parseLocation, getJobPosition } from '@utils';

const Writers = ({ location }) => {
  const isSSR = typeof window === 'undefined';
  const { position } = parseLocation(location);
  const jobPosition = getJobPosition(positions, position);
  const jobLink = jobPosition ? jobPosition.description : 'https://www.upstatement.com/jobs';

  return (
    <Layout location={location} positions={positions}>
      {position || isSSR ? (
        <Apply location={location} content={content} jobLink={jobLink} />
      ) : (
        <List list={positions} />
      )}
    </Layout>
  );
};

Writers.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Writers;
